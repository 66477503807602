<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
  .card {
    background-color: rgba(45, 52, 54, 1);
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
    padding: 1rem;
    margin: 2rem;
  }
</style>
